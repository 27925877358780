import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import Fonts from './Fonts';
import Style from './style';
import { ThemeProvider } from 'styled-components';
import { lightTheme } from 'scorer-ui-kit';
// export const HOST = '10.10.16.113';//'DEB-NUC8i7BE-G6BE946004YW.local';
export const HOST = window.location.host;//'deb-nuc8i7be-g6be946004z9.local'
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={lightTheme}>
      <App />
      <Fonts />
      <Style />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
