import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { LogoMark, LogoText } from '../svg';

const Container = styled.div`
  width: 350px;
  border-right: 1px solid hsla(0, 0%, 84%, 50%);
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const LogoContainer = styled.div`
  border-bottom: 1px solid hsla(0, 0%, 84%, 50%);
  padding: 20px 20px 15px;
  display: flex;
  flex-direction: row;
`;
const LogoIcon = styled(LogoMark)`
  margin-right: 15px;
`;

export const SidebarBox = styled.div<{ flex?: string }>`
  position: relative;
  border-bottom: 1px solid hsla(0, 0%, 84%, 50%);
  padding: 22px 20px 21px 20px;
  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    `}
`;

export const SidebarHeading = styled.div`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.35px;
  color: hsl(0, 0%, 50%);
  margin-bottom: 18px;
`;

export const SidebarLinkHeading = styled.div`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.35px;
  color: hsl(0, 0%, 50%);
`;

export const SidebarQuickLayout = styled.div<{ direction?: 'row' | 'column' }>`
  display: flex;
  ${({ direction }) =>
    direction === 'column'
      ? css`
          flex-direction: column;
          & > button:first-child {
            margin-bottom: 22px;
          }
        `
      : css`
          flex-direction: row;
          justify-content: space-between;
        `}
`;

export const BackLink = styled(Link)`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.35px;
  color: hsl(0, 0%, 50%);
`;

const SLink = styled(Link)`
  position: absolute;
  right: 30px;
  top: 15px;
  /* width: 80px; */
  /* height: 30px; */
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: hsl(0, 0%, 55%);
  border-radius: 3px;
  background-color: hsl(206, 70%, 96%);
  text-decoration: none;
  padding: 7px 20px;
`;

export const SidebarBoxInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const SidebarBoxInnerItem = styled.div`
  flex: 1;
  display: block;
`;

export const SidebarLink: React.FC<{ title: string; to: string }> = ({
  title,
  to,
}) => {
  return (
    <SidebarBox>
      <SidebarLinkHeading>{title}</SidebarLinkHeading>
      <SLink to={to}>Setup</SLink>
    </SidebarBox>
  );
};

const Sidebar: React.FC<{}> = ({ children }) => {
  return (
    <Container>
      <LogoContainer>
        <LogoIcon />
        <LogoText />
      </LogoContainer>

      {children}
    </Container>
  );
};

export default Sidebar;
