import React, { useState, useCallback, useEffect, useReducer } from 'react';

import {
  LineUI,
  LineReducer,
  LineSetContext,
  Button,
  EditCell,
  AlertBar,
} from 'scorer-ui-kit';

import Sidebar, { SidebarBox, SidebarQuickLayout } from '../components/Sidebar';
import { Layout, Content, MainContainer } from '../components/Layout';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import {API_ENDPOINT} from '../utils/Constants';

const Camareas: React.FC<{}> = () => {
  const [stacks, setStacks] = useState<string[]>([]);
  const [error, setError] = useState('');
  const [state, dispatch] = useReducer(LineReducer, []);
  const [cameraId, setCameraId] = React.useState('');
  const [objectId, setObjectId] = React.useState('');
  const [judgementPart, setJudgementPart] = React.useState('');
  const [areaId, setAreaId] = React.useState('');
  const [segArea, setSegArea] = React.useState('');
  const [areaName, setAreaName] = React.useState('');
  const [segAreaSize, setSegAreaSize] = useState('');
  const [segThreshold, setSegThreshold] = useState('');
  const [detectionThreshold, setDetectionThreshold] = useState('');
  const [alertConTime, setAlertConTime] = useState('');
  const [alertConHoldTime, setAlertConHoldTime] = useState('');
  const [camImg, setCamImg] = useState('');

  const handleCamera = (event: SelectChangeEvent) => {
    setAreaId('');
    setObjectId('');
    setJudgementPart('');
    setCameraId(event.target.value as string);
  };

  const handleObject = (event: SelectChangeEvent) => {
    setObjectId(event.target.value as string);
  };

  const handleJudgementPart = (event: SelectChangeEvent) => {
    setJudgementPart(event.target.value as string);
  };

  const handleSegArea = (event: SelectChangeEvent) => {
    setSegArea(event.target.value as string);
  };

  // validation
  const isSegAreaSizeValid = (num: number) => !(num > 0);
  const isSegThresholdValid = (num: number) =>
    !(num % 1 === 0) || !(num > 0 && num < 100);
  const isDetectionThresholdValid = (num: number) =>
    !(num % 1 === 0) || !(num > 0);
  const isAlertConTimeValid = (num: number) => !(num % 1 === 0) || !(num >= 0);
  const isAlertConHoldTimeValid = (num: number) =>
    !(num % 1 === 0) || !(num >= 0);

  function isEmpty(obj: any) {
    for (var x in obj) {
      return false;
    }
    return true;
  }

  const handleArea = (event: SelectChangeEvent) => {
    setSegArea('');
    setSegAreaSize('');
    setSegThreshold('');
    setDetectionThreshold('');
    setAlertConTime('');
    setAlertConHoldTime('');
    setAreaId(event.target.value as string);
    try {
      axios
        .get(API_ENDPOINT+'/api/camareas')
        .then((res) => {
          let data = res.data;
          if (!isEmpty(data)) {
            let areas: any = [];
            for (let i = 0; i < data['cameras'].length; i++) {
              if (data['cameras'][i][cameraId]) {
                areas = data['cameras'][i][cameraId]['areas'];
                if (event.target.value) {
                  if (areas[Number(event.target.value)]) {
                    setSegArea(areas[Number(event.target.value)].segArea);
                    setSegAreaSize(
                      areas[Number(event.target.value)].segAreaSize
                    );
                    setSegThreshold(
                      areas[Number(event.target.value)].segThreshold
                    );
                    setDetectionThreshold(
                      areas[Number(event.target.value)].detectionThreshold
                    );
                    setAlertConTime(
                      areas[Number(event.target.value)].alertConTime
                    );
                    setAlertConHoldTime(
                      areas[Number(event.target.value)].alertConHoldTime
                    );
                  }
                }
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      setError('Failed to fetch areas');
    }
  };

  const getStacks = useCallback(() => {
    axios.get(API_ENDPOINT + '/api/stacks')
      .then(res => {
        console.log(res.data)
        setStacks(res.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    getStacks();
  }, [getStacks]);

    
  const getImg = useCallback(() => {
    axios.get(API_ENDPOINT + '/api/image?id='+cameraId)
      .then(res => {
        setCamImg(res.data.img);
      })
      .catch(error => {
        console.error(error);
      });
  }, [cameraId]);


  useEffect(() => {
    getImg();
  }, [getImg]);

    
  const fetchAreas = useCallback(() => {
    try {
      axios
        .get(API_ENDPOINT+'/api/camareas')
        .then((res) => {
          let data = res.data;
          if (!isEmpty(data)) {
            let areas: any = [];
            for (let i = 0; i < data['cameras'].length; i++) {
              if (data['cameras'][i][cameraId]) {
                areas = data['cameras'][i][cameraId]['areas'];
                if (data['cameras'][i][cameraId]['object']) {
                  setObjectId(data['cameras'][i][cameraId]['object']);
                }
                if (data['cameras'][i][cameraId]['judgementPart']) {
                  setJudgementPart(
                    data['cameras'][i][cameraId]['judgementPart']
                  );
                }
              }
            }
            dispatch({
              type: 'LOAD',
              state: areas,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      setError('Failed to fetch areas');
    }
  }, [cameraId]);

  const areaList: any = [];

  for (let i = 0; i < state.length; i++) {
    areaList.push({ name: state[i].name, key: i.toString() });
  }

  const saveAreas = useCallback(async () => {
    if (
      isSegAreaSizeValid(Number(segAreaSize)) ||
      isSegThresholdValid(Number(segThreshold)) ||
      isDetectionThresholdValid(Number(detectionThreshold)) ||
      isAlertConTimeValid(Number(alertConTime)) ||
      isAlertConHoldTimeValid(Number(alertConHoldTime))
    ) {
      setError('入力漏れがあります。ご確認ください。');
    } else {
      try {
        axios
          .get(API_ENDPOINT+'/api/camareas')
          .then((res) => {
            let data = res.data;
            if (!isEmpty(data)) {
              let areaData: any = {};
              for (let i = 0; i < data['cameras'].length; i++) {
                if (data['cameras'][i][cameraId]) {
                  areaData = data['cameras'][i][cameraId];
                }
              }

              if (Object.keys(areaData).length === 0) {
                state[Number(areaId)].segArea = segArea;
                state[Number(areaId)].segAreaSize = Number(segAreaSize);
                state[Number(areaId)].segThreshold = Number(segThreshold);
                state[Number(areaId)].detectionThreshold =
                  Number(detectionThreshold);
                state[Number(areaId)].alertConTime = Number(alertConTime);
                state[Number(areaId)].alertConHoldTime =
                  Number(alertConHoldTime);
                data['cameras'].push({
                  [cameraId]: {
                    object: objectId,
                    judgementPart: judgementPart,
                    areas: state,
                  },
                });

                try {
                  axios
                    .put(API_ENDPOINT+'/api/camareas', JSON.stringify(data), {
                      headers: { 'Content-Type': 'application/json' },
                    })
                    .then((res) => {
                      setError('保存しました。');
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                } catch (error) {
                  console.log(error);
                }
              } else {
                if (state.length > 0) {
                  areaData.object = objectId;
                  areaData.judgementPart = judgementPart;
                  state[Number(areaId)].segArea = segArea;
                  state[Number(areaId)].segAreaSize = Number(segAreaSize);
                  state[Number(areaId)].segThreshold = Number(segThreshold);
                  state[Number(areaId)].detectionThreshold =
                    Number(detectionThreshold);
                  state[Number(areaId)].alertConTime = Number(alertConTime);
                  state[Number(areaId)].alertConHoldTime =
                    Number(alertConHoldTime);
                  areaData['areas'] = state;
                }
                areaData['areas'] = state;
                try {
                  axios
                    .put(API_ENDPOINT+'/api/camareas', JSON.stringify(data), {
                      headers: { 'Content-Type': 'application/json' },
                    })
                    .then((res) => {
                      setError('保存しました。');
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                } catch (error) {
                  console.log(error);
                }
              }
            } else {
              state[Number(areaId)].segArea = segArea;
              state[Number(areaId)].segAreaSize = Number(segAreaSize);
              state[Number(areaId)].segThreshold = Number(segThreshold);
              state[Number(areaId)].detectionThreshold =
                Number(detectionThreshold);
              state[Number(areaId)].alertConTime = Number(alertConTime);
              state[Number(areaId)].alertConHoldTime = Number(alertConHoldTime);
              try {
                axios
                  .put(
                    API_ENDPOINT+'/api/camareas',
                    JSON.stringify({
                      cameras: [
                        {
                          [cameraId]: {
                            object: objectId,
                            judgementPart: judgementPart,
                            areas: state,
                          },
                        },
                      ],
                    }),
                    { headers: { 'Content-Type': 'application/json' } }
                  )
                  .then((res) => {
                    setError('保存しました。');
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              } catch (error) {
                console.log(error);
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        setError('Failed to save areas');
      }
    }
  }, [
    state,
    cameraId,
    judgementPart,
    objectId,
    areaId,
    segArea,
    segAreaSize,
    segThreshold,
    detectionThreshold,
    alertConTime,
    alertConHoldTime,
  ]);

  const addArea = useCallback(async () => {
    let id = "id" + Math.random().toString(16).slice(2)
    dispatch({
      type: 'ADD_SET',
      data: {
        name: areaName,
        id: id,
        points: [
          { x: 0, y: 0 },
          { x: 500, y: 0 },
          { x: 500, y: 200 },
          { x: 0, y: 200 },
        ],
      },
    });
  }, [areaName]);

  const removeArea = useCallback(async (index) => {
    setAreaId('');
    dispatch({
      type: 'REMOVE_SET',
      index,
    });
  }, []);

  const addPoint = useCallback(async (index) => {
    dispatch({
      type: 'ADD_POINT',
      index,
    });
  }, []);

  const removePoint = useCallback(
    async (index) => {
      if (state[index].points.length <= 3) return;
      dispatch({
        type: 'REMOVE_POINT',
        index,
      });
    },
    [state]
  );

  const [data] = useState<any>([state]);
  const EditName = useCallback(
    async (name: string, key: any) => {
      const updatedData = [...data];
      const updatedRow = updatedData.find(({ id }) => id === key);
      if (updatedRow && name.length > 0) {
        updatedRow.name = name;
      }
      dispatch({
        type: 'RENAME_SET',
        index: key,
        data: {
          name: name,
        },
      });
    },
    [data]
  );

  useEffect(() => {
    fetchAreas();
  }, [fetchAreas]);

  return (
    <Layout>
      <Sidebar>
        <SidebarBox>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>カメラ選択</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={cameraId}
                label='camera'
                onChange={handleCamera}
              >
                {stacks.map((item: string, index: number) => (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </SidebarBox>
        {cameraId ? (
          <div>
            <SidebarBox>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id='demo-simple-select-label'>
                    検知対象選択
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={objectId}
                    label='検知対象選択'
                    onChange={handleObject}
                  >
                    <MenuItem value={'human'}>人</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </SidebarBox>
            <SidebarBox>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id='demo-simple-select-label'>
                    判定部位選択
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={judgementPart}
                    label='判定部位選択'
                    onChange={handleJudgementPart}
                  >
                    <MenuItem value={'foot'}>足元</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </SidebarBox>
            <SidebarBox>
              <TextField
                required
                id='outlined-basic'
                label='エリア名'
                variant='outlined'
                defaultValue={areaName}
                onChange={(event) => {
                  setAreaName(event.target.value as string);
                }}
              />
              <br></br>
              <br></br>
              <Button size='small' design='primary' onClick={addArea}>
                エリア追加
              </Button>
            </SidebarBox>
            <SidebarBox>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id='demo-simple-select-label'>
                    エリア選択
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={areaId}
                    label='エリア選択'
                    onChange={handleArea}
                  >
                    {areaList.map((item: any, index: number) => (
                      <MenuItem value={item.key} key={item.key}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </SidebarBox>
            {areaId && state.length !== 0 ? (
              <div>
                <SidebarBox>
                  <SidebarQuickLayout direction='row'>
                    <EditCell
                      alignment='right'
                      saveCallback={EditName}
                      defaultValue={`${state[Number(areaId)].name}`}
                      rowKey={`${areaId}`}
                    ></EditCell>
                  </SidebarQuickLayout>
                  <br></br>
                  <br></br>
                  <>
                    <Button
                      style={{ marginRight: '15px' }}
                      size='small'
                      design='primary'
                      onClick={() => addPoint(areaId)}
                    >
                      +
                    </Button>
                    <Button
                      size='small'
                      design='primary'
                      onClick={() => removePoint(areaId)}
                    >
                      -
                    </Button>
                    <br></br>
                    <br></br>
                    <Button
                      size='small'
                      design='danger'
                      onClick={() => removeArea(areaId)}
                      style={{ position: 'absolute', right: '5%' }}
                    >
                      削除
                    </Button>
                    <br></br>
                    <br></br>
                  </>
                </SidebarBox>
                <SidebarBox>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id='demo-simple-select-label'>
                        セグメンテーション判定
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={segArea}
                        label='セグメンテーション判定'
                        onChange={handleSegArea}
                      >
                        <MenuItem value={'利用なし'}>利用なし</MenuItem>
                        <MenuItem value={'水面'}>水面</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </SidebarBox>
                <SidebarBox>
                  <Box sx={{ minWidth: 120 }}>
                    <TextField
                      id='outlined-basic'
                      label='判定大きさ'
                      variant='outlined'
                      required
                      value={segAreaSize}
                      error={isSegAreaSizeValid(Number(segAreaSize))}
                      helperText={
                        segAreaSize.length === 0
                          ? '入力ください'
                          : isSegAreaSizeValid(Number(segAreaSize))
                          ? '入力した値が正しくない'
                          : ''
                      }
                      inputProps={{ type: 'number', pattern: '[0-9]*' }}
                      onChange={(event) => setSegAreaSize(event.target.value)}
                    />
                  </Box>
                </SidebarBox>
                <SidebarBox>
                  <Box sx={{ minWidth: 120 }}>
                    <TextField
                      id='outlined-basic'
                      label='判定しきい値'
                      variant='outlined'
                      required
                      value={segThreshold}
                      error={isSegThresholdValid(Number(segThreshold))}
                      helperText={
                        segThreshold.length === 0
                          ? '入力ください'
                          : isSegThresholdValid(Number(segThreshold))
                          ? '入力した値が正しくない'
                          : ''
                      }
                      inputProps={{ type: 'number', pattern: '[0-9]*' }}
                      onChange={(event) => setSegThreshold(event.target.value)}
                    />
                  </Box>
                </SidebarBox>
                <SidebarBox>
                  <Box sx={{ minWidth: 120 }}>
                    <TextField
                      id='outlined-basic'
                      label='発報閾値人数'
                      variant='outlined'
                      required
                      value={detectionThreshold}
                      error={isDetectionThresholdValid(
                        Number(detectionThreshold)
                      )}
                      helperText={
                        detectionThreshold.length === 0
                          ? '入力ください'
                          : isDetectionThresholdValid(
                              Number(detectionThreshold)
                            )
                          ? '入力した値が正しくない'
                          : ''
                      }
                      inputProps={{ type: 'number', pattern: '[0-9]*' }}
                      onChange={(event) =>
                        setDetectionThreshold(event.target.value)
                      }
                    />
                  </Box>
                </SidebarBox>
                <SidebarBox>
                  <Box sx={{ minWidth: 120 }}>
                    <TextField
                      id='outlined-basic'
                      label='継続回数'
                      variant='outlined'
                      required
                      value={alertConTime}
                      error={isAlertConTimeValid(Number(alertConTime))}
                      helperText={
                        alertConTime.length === 0
                          ? '入力ください'
                          : isAlertConTimeValid(Number(alertConTime))
                          ? '入力した値が正しくない'
                          : ''
                      }
                      inputProps={{ type: 'number', pattern: '[0-9]*' }}
                      onChange={(event) => setAlertConTime(event.target.value)}
                    />
                  </Box>
                </SidebarBox>
                <SidebarBox>
                  <Box sx={{ minWidth: 120 }}>
                    <TextField
                      id='outlined-basic'
                      label='抑止時間'
                      variant='outlined'
                      required
                      value={alertConHoldTime}
                      error={isAlertConHoldTimeValid(Number(alertConHoldTime))}
                      helperText={
                        alertConHoldTime.length === 0
                          ? '入力ください'
                          : isAlertConHoldTimeValid(Number(alertConHoldTime))
                          ? '入力した値が正しくない'
                          : ''
                      }
                      inputProps={{ type: 'number', pattern: '[0-9]*' }}
                      onChange={(event) =>
                        setAlertConHoldTime(event.target.value)
                      }
                    />
                  </Box>
                </SidebarBox>
              </div>
            ) : (
              ''
            )}
            <SidebarBox flex='1'>
              <SidebarQuickLayout direction='column'>
                <Button size='small' design='primary' onClick={saveAreas}>
                  Save
                </Button>
              </SidebarQuickLayout>
            </SidebarBox>
          </div>
        ) : (
          ''
        )}
      </Sidebar>
      <MainContainer>
        <Content>
          {cameraId ? (
            <div>
              <AlertBar message={error} type='error' />
              <br></br>
              <br></br>
              <LineSetContext.Provider value={{ state, dispatch }}>
                <LineUI options={{ showSetIndex: true }} src={camImg} />
              </LineSetContext.Provider>
            </div>
          ) : (
            ''
          )}
        </Content>
      </MainContainer>
    </Layout>
  );
};

export default Camareas;
